import React from 'react';
import { Link } from 'gatsby';

import { ROUTES } from 'constants/common';

import { Button } from 'components/UI/Button';
import { Wrapper, FormWrapper } from 'components/Contact/styled';
import {
  ButtonWrapper,
  FormTitle,
  SuccessMessage,
  SuccessWrapper,
  Wrapper as Container,
} from 'components/ContactForm/styled';

const ContactData = () => {
  return (
    <Wrapper>
      <Container>
        <FormWrapper>
          <SuccessWrapper>
            <FormTitle>
              Thank <span>you</span>
            </FormTitle>
            <SuccessMessage>the form was successfully submitted</SuccessMessage>
            <ButtonWrapper>
              <Link to={ROUTES.root}>
                <Button>Back to home</Button>
              </Link>
            </ButtonWrapper>
          </SuccessWrapper>
        </FormWrapper>
      </Container>
    </Wrapper>
  );
};

export default ContactData;
