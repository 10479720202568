import styled from 'styles/styled';

const Input = styled.input<{ isValid?: boolean }>`
  background-color: transparent;
  border-bottom: 2px solid
    ${({ theme, isValid = true }) =>
      isValid ? theme.palette.light : theme.palette.danger};
  padding-bottom: 6px;
  outline: none;
  user-select: text;
  width: 100%;
  color: ${({ theme }) => theme.palette.light};
  font-size: ${({ theme }) => theme.fontSize.smallBase};
  line-height: 1.2;
  letter-spacing: 0.75px;

  ::placeholder {
    color: ${({ theme }) => theme.palette.light};
    opacity: 0.6;

    @media ${({ theme }) => theme.devices.medium} {
      opacity: 0.39;
    }
  }
`;

export default Input;
