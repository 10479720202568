import styled from 'styles/styled';

export const Wrapper = styled.div`
  padding: 30px 20px 100px;
  margin: 0 auto;
  max-width: ${({ theme }) => theme.dimensions.contentMaxWidthMedium}px;

  @media ${({ theme }) => theme.devices.medium} {
    flex-basis: 50%;
    padding: 30px 50px 100px;
  }

  @media ${({ theme }) => theme.devices.large} {
    video {
      left: 0;
      width: 100vw;
    }
  }
`;

export const FormWrapper = styled.div`
  @media ${({ theme }) => theme.devices.medium} {
    align-items: center;
    display: flex;
    justify-content: flex-start;

    > div:first-child {
      flex-grow: 1;
    }
  }
`;

export const TextWrapper = styled.div`
  color: ${({ theme }) => theme.palette.light};
  margin-bottom: 30px;
  text-align: center;

  h1 {
    font-size: ${({ theme }) => theme.fontSize.title};
    font-weight: 400;
    margin: 0 auto 20px;
    max-width: 350px;
  }

  @media ${({ theme }) => theme.devices.medium} {
    order: 1;
    padding-left: 50px;
    text-align: left;

    h1,
    p {
      margin: 0 0 20px;
    }
  }
`;

export const InfoWrapper = styled.div`
  a {
    display: block;
  }
`;

export const DataProcessingWrapper = styled.section`
  margin-top: 30px;

  p + P {
    margin-top: 10px;
  }

  p,
  a {
    font-size: 11px;
  }

  a {
    text-decoration: underline;
  }

  @media ${({ theme }) => theme.devices.medium} {
    margin-top: 70px;
  }
`;
