import styled from 'styles/styled';
import Input from 'components/UI/Input';

export const Wrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.65);
  border: 2px solid ${({ theme }) => theme.palette.light};
  border-radius: 25px;
  margin: 0 auto;
  max-width: 400px;
  padding: 50px 20px;

  @media ${({ theme }) => theme.devices.large} {
  }
`;

export const InputsWrapper = styled.div`
  margin: 30px 0;

  textarea {
    ::placeholder {
      position: relative;
      top: 5px;
    }
  }
`;

export const Form = styled.form`
  text-align: center;
`;

export const FormTitle = styled.h2`
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontSize.titleMobile};

  span {
    color: ${({ theme }) => theme.palette.primary};
  }

  @media ${({ theme }) => theme.devices.medium} {
    font-size: ${({ theme }) => theme.fontSize.title};
  }
`;

export const ButtonWrapper = styled.div`
  button {
    display: inline-block;
    padding: 10px 50px;
    font-size: 15px;
  }
`;

export const StyledInput = styled(Input)<{ isValid?: boolean }>`
  resize: vertical;
  min-height: 29px;
  max-height: 500px;
  margin-bottom: 30px;
  padding-bottom: 0;
  border-bottom-color: ${({ isValid = true, theme }) =>
    !isValid && theme.palette.danger};
`;

export const SuccessWrapper = styled.div`
  text-align: center;

  @media ${({ theme }) => theme.devices.medium} {
  }
`;

export const SuccessMessage = styled.p`
  font-size: ${({ theme }) => theme.fontSize.smallBase};
  text-align: center;
  padding: 10px 0 40px;

  @media ${({ theme }) => theme.devices.medium} {
    font-size: ${({ theme }) => theme.fontSize.subtitleMobile};
  }
`;
